import $ from "jquery";
import React, { useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import { Row, Col, Button, Tabs, Table, Form, Input } from "antd";
import { Container } from "react-bootstrap";
import Footer from "../../components/layout/Footer";

import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";
import "magnific-popup/dist/magnific-popup.css";
import BannerIndex from "../Home Banner/Index";
import CurrencyIndex from "../Currency Chart/CurrencyIndex";
import CurrencyRateTable from "../Currency_Rate/Index";
import AboutIndex from "../AboutUs";
import TextArea from "antd/lib/input/TextArea";
import ContactUsIndex from "../ContactUs";

const { TabPane } = Tabs;



function Home() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    // Mimic the behavior of jQuery once the component is mounted
    $(".elementor-section.mil-dark-bg > .elementor-container").addClass(
      "mi-invert-fix"
    );
    $(".elementor-element.mil-dark-bg > div").addClass("mi-invert-fix");

    const timeline = gsap.timeline();
    timeline.to(".mil-preloader-animation", { opacity: 1 });

    timeline.fromTo(
      ".mil-animation-1 .mil-h3",
      { y: "30px", opacity: 0 },
      { y: "0px", opacity: 1, stagger: 1.4 }
    );
    timeline.to(".mil-animation-1 .mil-h3", { opacity: 0, y: "-30" }, "+=1.3");

    timeline.fromTo(
      ".mil-reveal-box",
      { opacity: 0 },
      { opacity: 1, x: "-30" }
    );
    timeline
      .to(".mil-reveal-box", { width: "100%", x: 0 }, "+=.1")
      .duration(1.45);
    timeline.to(".mil-reveal-box", { right: "0" });
    timeline.to(".mil-reveal-box", { width: "0%" }).duration(1.3);

    timeline.fromTo(
      ".mil-animation-2 .mil-h3",
      { opacity: 0 },
      { opacity: 1 },
      "-=.5"
    );
    timeline
      .to(".mil-animation-2 .mil-h3", { opacity: 0, y: "-30" }, "+=1.5")
      .duration(1.6);

    timeline
      .to(".mil-preloader", { opacity: 0, ease: "sine" }, "+=1.2")
      .duration(1.8);
    if ($(".mil-preloader").length) {
      timeline
        .fromTo(
          ".mil-up",
          { opacity: 0, y: 40, scale: 0.98, ease: "sine" },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            onComplete: function () {
              $(".mil-preloader").addClass("mil-hidden");
            },
          },
          "-=1"
        )
        .duration(3.8);
    }
  }, []);

  
  useEffect(() => {
    $(".mil-menu-btn").on("click", function () {
      $(".mil-menu-btn").toggleClass("mil-active");
      $(".mil-menu").toggleClass("mil-active");
      $(".mil-menu-frame").toggleClass("mil-active");
    });
    $(".mil-has-children > a").on("click", function (e) {
      if ($(this).hasClass("mil-active")) {
        $(".mil-has-children > ul").removeClass("mil-active");
        $(".mil-has-children > a").removeClass("mil-active");
      } else {
        $(this).toggleClass("mil-active");
        $(this).next().toggleClass("mil-active");
      }
      return false;
    });
  });

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = document.querySelectorAll(".tag"); // Get all elements with class 'tag'

      tags.forEach((tag) => {
        const tagTop = tag.getBoundingClientRect().top + window.scrollY;
        if (tagTop < pageBottom) {
          tag.classList.add("visible");
        } else {
          tag.classList.remove("visible");
        }
      });
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Animation for elements with class .mil-up
    const appearance = document.querySelectorAll(".mil-up");
    appearance.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 40, scale: 0.98, ease: "sine" },
        {
          y: 0,
          opacity: 1,
          scale: 1,
          duration: 0.4,
          scrollTrigger: {
            trigger: section,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Animation for elements with class .mil-scale
    const scaleImage = document.querySelectorAll(".mil-scale");
    scaleImage.forEach((section) => {
      const value1 = section.dataset.value1;
      const value2 = section.dataset.value2;
      gsap.fromTo(
        section,
        { ease: "sine", scale: value1 },
        {
          scale: value2,
          scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Animation for elements with class .mil-parallax
    const parallaxImage = document.querySelectorAll(".mil-parallax");
    if (window.innerWidth > 960) {
      parallaxImage.forEach((section) => {
        const value1 = section.dataset.value1;
        const value2 = section.dataset.value2;
        gsap.fromTo(
          section,
          { ease: "sine", y: value1 },
          {
            y: value2,
            scrollTrigger: {
              trigger: section,
              scrub: true,
              toggleActions: "play none none reverse",
            },
          }
        );
      });
    }

    // Animation for elements with class .mil-rotate
    const rotate = document.querySelectorAll(".mil-rotate");
    rotate.forEach((section) => {
      const value = section.dataset.value;
      gsap.fromTo(
        section,
        { ease: "sine", rotate: 0 },
        {
          rotate: value,
          scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);
  useEffect(() => {
    const cursor = document.querySelector(".mil-ball");
    gsap.set(cursor, { xPercent: -50, yPercent: -50 });

    // Move the cursor on pointer move
    const movecursor = (e) => {
      gsap.to(cursor, {
        duration: 0.6,
        ease: "sine",
        x: e.clientX,
        y: e.clientY,
      });
    };
    document.addEventListener("pointermove", movecursor);

    // Mouseover and mouseleave effects for various elements
    const handleMouseOver = (element, width, height, opacity = 1) => {
      gsap.to(cursor, {
        duration: 0.2,
        width,
        height,
        opacity,
        ease: "sine",
      });
    };

    const handleMouseLeave = (width, height, opacity = 0.1) => {
      gsap.to(cursor, {
        duration: 0.2,
        width,
        height,
        opacity,
        ease: "sine",
      });
    };

    const handleAccentCursorOver = (background) => {
      gsap.to(cursor, {
        duration: 0.2,
        background,
        ease: "sine",
      });
      cursor.classList.add("mil-accent");
    };

    const handleAccentCursorLeave = (background) => {
      gsap.to(cursor, {
        duration: 0.2,
        background,
        ease: "sine",
      });
      cursor.classList.remove("mil-accent");
    };

    const handleIconScale = (selector, scale) => {
      gsap.to(selector, { duration: 0.2, scale, ease: "sine" });
    };

    // Mouseover and mouseleave events for specific classes
    const elementsToWatch = [
      { selector: ".mil-drag", width: 90, height: 90 },
      { selector: ".mil-more", width: 90, height: 90 },
      { selector: ".mil-choose", width: 90, height: 90 },
    ];

    elementsToWatch.forEach((el) => {
      const elements = document.querySelectorAll(el.selector);
      elements.forEach((element) => {
        element.addEventListener("mouseover", () =>
          handleMouseOver(element, el.width, el.height)
        );
        element.addEventListener("mouseleave", () => handleMouseLeave(20, 20));
      });
    });

    // Accent cursor hover
    const accentElements = document.querySelectorAll(".mil-accent-cursor");
    accentElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleAccentCursorOver("#accentColor")
      );
      element.addEventListener("mouseleave", () =>
        handleAccentCursorLeave("#darkColor")
      );
    });

    // Icon scaling
    const dragElements = document.querySelectorAll(".mil-drag");
    dragElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-icon-1", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-icon-1", 0)
      );
    });

    const moreElements = document.querySelectorAll(".mil-more");
    moreElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-more-text", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-more-text", 0)
      );
    });

    const chooseElements = document.querySelectorAll(".mil-choose");
    chooseElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-choose-text", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-choose-text", 0)
      );
    });

    // Reset cursor on inputs and links
    const inputAndLinkSelectors = `a:not(.mil-choose, .mil-more, .mil-drag, .mil-accent-cursor), 
                                   input, textarea, .mil-accordion-menu`;

    const resetElements = document.querySelectorAll(inputAndLinkSelectors);
    resetElements.forEach((element) => {
      element.addEventListener("mouseover", () => {
        gsap.to(cursor, { duration: 0.2, scale: 0, ease: "sine" });
        gsap.to(".mil-ball svg", { duration: 0.2, scale: 0 });
      });
      element.addEventListener("mouseleave", () => {
        gsap.to(cursor, { duration: 0.2, scale: 1, ease: "sine" });
        gsap.to(".mil-ball svg", { duration: 0.2, scale: 1 });
      });
    });

    // Cleanup function to remove event listeners on unmount
    return () => {
      document.removeEventListener("pointermove", movecursor);
      elementsToWatch.forEach((el) => {
        const elements = document.querySelectorAll(el.selector);
        elements.forEach((element) => {
          element.removeEventListener("mouseover", () =>
            handleMouseOver(element, el.width, el.height)
          );
          element.removeEventListener("mouseleave", () =>
            handleMouseLeave(20, 20)
          );
        });
      });
      accentElements.forEach((element) => {
        element.removeEventListener("mouseover", () =>
          handleAccentCursorOver("#accentColor")
        );
        element.removeEventListener("mouseleave", () =>
          handleAccentCursorLeave("#darkColor")
        );
      });
      resetElements.forEach((element) => {
        element.removeEventListener("mouseover", () => {
          gsap.to(cursor, { duration: 0.2, scale: 0, ease: "sine" });
          gsap.to(".mil-ball svg", { duration: 0.2, scale: 0 });
        });
        element.removeEventListener("mouseleave", () => {
          gsap.to(cursor, { duration: 0.2, scale: 1, ease: "sine" });
          gsap.to(".mil-ball svg", { duration: 0.2, scale: 1 });
        });
      });
    };
  }, []);

  return (
    <>
      <div
        className="mil-wrapper"
        id="top"
        data-smooth-scroll={1}
        data-dark-color=""
        data-light-color=""
        data-accent-color=""
      >
        {/* cursor */}
        <div className="mil-ball">
          <span className="mil-icon-1">
            <svg viewBox="0 0 128 128">
              <path d="M106.1,41.9c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L116.8,61H11.2l14.9-14.9c1.2-1.2,1.2-3.1,0-4.2	c-1.2-1.2-3.1-1.2-4.2,0l-20,20c-1.2,1.2-1.2,3.1,0,4.2l20,20c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2	L11.2,67h105.5l-14.9,14.9c-1.2,1.2-1.2,3.1,0,4.2c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9l20-20c1.2-1.2,1.2-3.1,0-4.2L106.1,41.9	z" />
            </svg>
          </span>
          <div className="mil-more-text">More</div>
          <div className="mil-choose-text">Сhoose</div>
        </div>
        {/* cursor end */} {/* preloader */}
        <Header />
        
        {/* menu */} {/* content */}
        <div className="mil-content">
          <div id="swupMain" className="mil-main-transition">
            <div
              id="post-15"
              className="post-15 page type-page status-publish hentry"
            >
              <div
                data-elementor-type="wp-page"
                data-elementor-id={15}
                className="elementor elementor-15"
              >
               <BannerIndex /> 
              <CurrencyRateTable />
               <AboutIndex />
              </div>
            </div>
            {/* #post-15 */}
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-a1fac7c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="a1fac7c"
              data-element_type="section"
              id="contact"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c665942"
                  data-id="c665942"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-e365340 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="e365340"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ceabdc7"
                          data-id="ceabdc7"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-893ea49 elementor-widget elementor-widget-ashley-about-us"
                              data-id="893ea49"
                              data-element_type="widget"
                              data-widget_type="ashley-about-us.default"
                            >
                              <div className="elementor-widget-container">
                                <h3 className="mil-h3 mil-title-el mil-up mil-mb-30 contact-from-box">
                                  {" "}
                                  <span>
                                    {" "}
                                    Let's <span className="mil-thin">
                                      Talk
                                    </span>{" "}
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                   <ContactUsIndex />
                  </div>
                </div>
              </div>
            </section>
            {/* hidden elements */}
            <div className="mil-hidden-elements">
              <div className="mil-dodecahedron">
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div className="mil-pentagon">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="mil-arrow"
              >
                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z" />
              </svg>
              <svg
                width={250}
                viewBox="0 0 300 1404"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mil-lines"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 892L1 941H299V892C299 809.71 232.29 743 150 743C67.7096 743 1 809.71 1 892ZM0 942H300V892C300 809.157 232.843 742 150 742C67.1573 742 0 809.157 0 892L0 942Z"
                  className="mil-move"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M299 146V97L1 97V146C1 228.29 67.7096 295 150 295C232.29 295 299 228.29 299 146ZM300 96L0 96V146C0 228.843 67.1573 296 150 296C232.843 296 300 228.843 300 146V96Z"
                  className="mil-move"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M299 1H1V1403H299V1ZM0 0V1404H300V0H0Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M150 -4.37115e-08L150 1404L149 1404L149 0L150 -4.37115e-08Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M150 1324C232.29 1324 299 1257.29 299 1175C299 1092.71 232.29 1026 150 1026C67.7096 1026 1 1092.71 1 1175C1 1257.29 67.7096 1324 150 1324ZM150 1325C232.843 1325 300 1257.84 300 1175C300 1092.16 232.843 1025 150 1025C67.1573 1025 0 1092.16 0 1175C0 1257.84 67.1573 1325 150 1325Z"
                  className="mil-move"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M300 1175H0V1174H300V1175Z"
                  className="mil-move"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M150 678C232.29 678 299 611.29 299 529C299 446.71 232.29 380 150 380C67.7096 380 1 446.71 1 529C1 611.29 67.7096 678 150 678ZM150 679C232.843 679 300 611.843 300 529C300 446.157 232.843 379 150 379C67.1573 379 0 446.157 0 529C0 611.843 67.1573 679 150 679Z"
                  className="mil-move"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M299 380H1V678H299V380ZM0 379V679H300V379H0Z"
                  className="mil-move"
                />
              </svg>
            </div>
            {/* hidden elements end */}
            {/* footer */}
          <Footer />
            {/* footer end */}
          </div>
        </div>
        {/* content */}
      </div>
      {/* wrapper end */} {/* bslthemes.com buttons html begin */}
      <div className="bsl-popup" data-theme="ashley" data-category="wordpress">
        <div className="bsl-popup__buttons" />
        <div className="bsl-popup__content bsl-popup__content-related">
          <div className="bsl-popup__menu" />
          <div className="bsl-popup__tabs">
            <div className="bsl-popup__tab bsl-popup__tab-demo" />
            <div className="bsl-popup__tab bsl-popup__tab-all" />
            <div className="bsl-popup__tab bsl-popup__tab-related" />
          </div>
        </div>
        <div className="bsl-popup__content bsl-popup__content-help" />
      </div>
    </>
  );
}

export default Home;
