import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
  Layout,
  Typography,
} from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { Container } from "react-bootstrap";

function Header({}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="mil-preloader">
        <div className="mil-preloader-animation">
          <div className="mil-pos-abs mil-animation-1">
            <p className="mil-h3 mil-muted mil-thin">Pioneering</p>
            <p className="mil-h3 mil-muted">Creative</p>
            <p className="mil-h3 mil-muted mil-thin">Excellence</p>
          </div>
          <div className="mil-pos-abs mil-animation-2">
            <div className="mil-reveal-frame">
              <p className="mil-reveal-box" />
              <p className="mil-h3 mil-muted mil-thin">Currency </p>
            </div>
          </div>
        </div>
      </div>
      {/* preloader end */} {/* scrollbar progress */}
      <div className="mil-progress-track">
        <div className="mil-progress" />
      </div>
      {/* scrollbar progress end */} {/* curtain */}
      <div className="mil-curtain" />
      {/* curtain end */} {/* frame */}
      <div className="mil-frame">
        <div className="mil-frame-top">
          <div
            data-elementor-type="wp-post"
            data-elementor-id={18}
            className="elementor elementor-18"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-6f677a6 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              data-id="6f677a6"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-af46f5c"
                  data-id="af46f5c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-ec03d90 elementor-widget__width-initial elementor-widget elementor-widget-ashley-header-logo"
                      data-id="ec03d90"
                      data-element_type="widget"
                      data-widget_type="ashley-header-logo.default"
                    >
                      <div className="elementor-widget-container">
                        {" "}
                        <a href="index.html" className="mil-logo">
                          <span>A.</span>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-bae398c"
                  data-id="bae398c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap" />
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2607533"
                  data-id={2607533}
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-284cf93 elementor-widget elementor-widget-ashley-header-menu"
                      data-id="284cf93"
                      data-element_type="widget"
                      data-widget_type="ashley-header-menu.default"
                    >
                      <div className="elementor-widget-container">
                        {/* menu button */}
                        <div className="mil-menu-btn">
                          {" "}
                          <span />
                        </div>
                        {/* menu button end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="mil-frame-bottom">
          <div className="mil-current-page" />
          <div className="mil-back-to-top">
            {" "}
            <a href="#top" className="mil-link mil-dark mil-arrow-place">
              {" "}
              <span>Back to top</span>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="mil-arrow"
              >
                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      {/* frame end */} {/* menu */}
      <div className="mil-menu-frame">
          {/* frame clone */}
          <div className="mil-frame-top">
            <div
              data-elementor-type="wp-post"
              data-elementor-id={18}
              className="elementor elementor-18"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-6f677a6 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="6f677a6"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-af46f5c"
                    data-id="af46f5c"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-ec03d90 elementor-widget__width-initial elementor-widget elementor-widget-ashley-header-logo"
                        data-id="ec03d90"
                        data-element_type="widget"
                        data-widget_type="ashley-header-logo.default"
                      >
                        <div className="elementor-widget-container">
                          {" "}
                          <a href="" className="mil-logo">
                            <span>A.</span>{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-bae398c"
                    data-id="bae398c"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap" />
                  </div>
                  <div
                    className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-2607533"
                    data-id={2607533}
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-284cf93 elementor-widget elementor-widget-ashley-header-menu"
                        data-id="284cf93"
                        data-element_type="widget"
                        data-widget_type="ashley-header-menu.default"
                      >
                        <div className="elementor-widget-container">
                          {/* menu button */}
                          <div className="mil-menu-btn">
                            {" "}
                            <span />
                          </div>
                          {/* menu button end */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* frame clone end */}
          <div className="container">
            <div className="mil-menu-content">
              <div
                data-elementor-type="wp-post"
                data-elementor-id={899}
                className="elementor elementor-899"
              >
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-c3cedc0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="c3cedc0"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ec964dd"
                      data-id="ec964dd"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-d7043da elementor-widget elementor-widget-ashley-popup-menu"
                          data-id="d7043da"
                          data-element_type="widget"
                          data-widget_type="ashley-popup-menu.default"
                        >
                          <div className="elementor-widget-container">
                            <nav className="mil-main-menu" id="swupMenu">
                              <ul
                                id="menu-main-menu"
                                className="mil-main-menu-list"
                              >
                                <li
                                  id="menu-item-198"
                                  className="menu-item menu-item-type-post_type menu-item-object-page"
                                >
                                  <a className="mil-item-link" href="">
                                    Homepage
                                  </a>
                                </li>
                                <li
                                  id="menu-item-784"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page"
                                >
                                  <a
                                    className="mil-item-link"
                                    href="#currency_conversion_rates"
                                  >
                                    Currency Conversion Rates
                                  </a>
                                </li>
                                <li
                                  id="menu-item-784"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page"
                                >
                                  <a className="mil-item-link" href="#aboutus">
                                    About
                                  </a>
                                </li>
                                <li
                                  id="menu-item-784"
                                  className=" menu-item menu-item-type-post_type menu-item-object-page"
                                >
                                  <a
                                    className="mil-item-link"
                                    href="#contactus"
                                  >
                                    Contact
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Header;
