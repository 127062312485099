import React, { useEffect, useState } from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import { Spinner } from "react-bootstrap";

const CurrencyIndex = () => {
  return (
    <>
     
    </>
  );
};

export default CurrencyIndex;
