import $ from "jquery";
import { Row, Col, Button, Table, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import Flag from "react-world-flags";
import useRequest from "../../hooks/useRequest";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import Header from "../../components/layout/Header";

import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from "gsap/all";
import "magnific-popup/dist/magnific-popup.css";
const { Option } = Select;

function ExchangeIndex() {
  const { request } = useRequest();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [amount, setAmount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [isBuy, setIsBuy] = useState(false);
  const [reverseAmount, setReverseAmount] = useState(0);

  const navigate = useNavigate();
  const columns = [
    {
      title: "Currency / SGD",
      key: "currency",
      dataIndex: "currency",
      render: (_, { currency }) => {
        let code = currency?.substring(0, 2);
        return (
          <>
            <Flag code={code} style={{ width: "32px", marginRight: "8px" }} />
            <span>{currency}</span>
          </>
        );
      },
    },
    {
      title: "BUYING RATE",
      key: "buy",
      dataIndex: "buy",
      className: "buy-column",
      render: (_, { buy }) => {
        return <span>{buy?.toFixed(4)}</span>;
      },
    },
    {
      title: "SELLING RATE",
      key: "sell",
      dataIndex: "sell",
      className: "sell-column",
      render: (_, { sell }) => {
        return <span>{sell?.toFixed(4)}</span>;
      },
    },
  ];

  const getForexData = () => {
    request({
      url: apiPath.common.forexPrice,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        setData(data?.data);
        // setPagination((prev) => {
        //   return {
        //     ...prev,
        //     page: 1,
        //     pageSize: 10,
        //     total: data?.data?.length || 0,
        //   };
        // });
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getForexData();
  }, []);

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
    let flagAmount = data.find((item) => item?.currency === value);
    console.log(flagAmount);
    setConversionRate(flagAmount?.buy);
    setConvertedAmount(amount * flagAmount?.buy);
    setReverseAmount(amount / flagAmount?.buy);
  };

  const handleAmountChange = (e) => {
    let value = parseInt(e.target.value || 0);
    setAmount(value);
    setConvertedAmount(value * conversionRate);
    setReverseAmount(value / conversionRate);
  };

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  useEffect(() => {
    // Mimic the behavior of jQuery once the component is mounted
    $(".elementor-section.mil-dark-bg > .elementor-container").addClass(
      "mi-invert-fix"
    );
    $(".elementor-element.mil-dark-bg > div").addClass("mi-invert-fix");

    const timeline = gsap.timeline();
    timeline.to(".mil-preloader-animation", { opacity: 1 });

    timeline.fromTo(
      ".mil-animation-1 .mil-h3",
      { y: "30px", opacity: 0 },
      { y: "0px", opacity: 1, stagger: 1.4 }
    );
    timeline.to(".mil-animation-1 .mil-h3", { opacity: 0, y: "-30" }, "+=1.3");

    timeline.fromTo(
      ".mil-reveal-box",
      { opacity: 0 },
      { opacity: 1, x: "-30" }
    );
    timeline
      .to(".mil-reveal-box", { width: "100%", x: 0 }, "+=.1")
      .duration(1.45);
    timeline.to(".mil-reveal-box", { right: "0" });
    timeline.to(".mil-reveal-box", { width: "0%" }).duration(1.3);

    timeline.fromTo(
      ".mil-animation-2 .mil-h3",
      { opacity: 0 },
      { opacity: 1 },
      "-=.5"
    );
    timeline
      .to(".mil-animation-2 .mil-h3", { opacity: 0, y: "-30" }, "+=1.5")
      .duration(1.6);

    timeline
      .to(".mil-preloader", { opacity: 0, ease: "sine" }, "+=1.2")
      .duration(1.8);
    if ($(".mil-preloader").length) {
      timeline
        .fromTo(
          ".mil-up",
          { opacity: 0, y: 40, scale: 0.98, ease: "sine" },
          {
            y: 0,
            opacity: 1,
            scale: 1,
            onComplete: function () {
              $(".mil-preloader").addClass("mil-hidden");
            },
          },
          "-=1"
        )
        .duration(3.8);
    }
  }, []);

  
  useEffect(() => {
    $(".mil-menu-btn").on("click", function () {
      $(".mil-menu-btn").toggleClass("mil-active");
      $(".mil-menu").toggleClass("mil-active");
      $(".mil-menu-frame").toggleClass("mil-active");
    });
    $(".mil-has-children > a").on("click", function (e) {
      if ($(this).hasClass("mil-active")) {
        $(".mil-has-children > ul").removeClass("mil-active");
        $(".mil-has-children > a").removeClass("mil-active");
      } else {
        $(this).toggleClass("mil-active");
        $(this).next().toggleClass("mil-active");
      }
      return false;
    });
  });

  useEffect(() => {
    const handleScroll = () => {
      const pageTop = window.scrollY;
      const pageBottom = pageTop + window.innerHeight;
      const tags = document.querySelectorAll(".tag"); // Get all elements with class 'tag'

      tags.forEach((tag) => {
        const tagTop = tag.getBoundingClientRect().top + window.scrollY;
        if (tagTop < pageBottom) {
          tag.classList.add("visible");
        } else {
          tag.classList.remove("visible");
        }
      });
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Animation for elements with class .mil-up
    const appearance = document.querySelectorAll(".mil-up");
    appearance.forEach((section) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 40, scale: 0.98, ease: "sine" },
        {
          y: 0,
          opacity: 1,
          scale: 1,
          duration: 0.4,
          scrollTrigger: {
            trigger: section,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Animation for elements with class .mil-scale
    const scaleImage = document.querySelectorAll(".mil-scale");
    scaleImage.forEach((section) => {
      const value1 = section.dataset.value1;
      const value2 = section.dataset.value2;
      gsap.fromTo(
        section,
        { ease: "sine", scale: value1 },
        {
          scale: value2,
          scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    // Animation for elements with class .mil-parallax
    const parallaxImage = document.querySelectorAll(".mil-parallax");
    if (window.innerWidth > 960) {
      parallaxImage.forEach((section) => {
        const value1 = section.dataset.value1;
        const value2 = section.dataset.value2;
        gsap.fromTo(
          section,
          { ease: "sine", y: value1 },
          {
            y: value2,
            scrollTrigger: {
              trigger: section,
              scrub: true,
              toggleActions: "play none none reverse",
            },
          }
        );
      });
    }

    // Animation for elements with class .mil-rotate
    const rotate = document.querySelectorAll(".mil-rotate");
    rotate.forEach((section) => {
      const value = section.dataset.value;
      gsap.fromTo(
        section,
        { ease: "sine", rotate: 0 },
        {
          rotate: value,
          scrollTrigger: {
            trigger: section,
            scrub: true,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);
  useEffect(() => {
    const cursor = document.querySelector(".mil-ball");
    gsap.set(cursor, { xPercent: -50, yPercent: -50 });

    // Move the cursor on pointer move
    const movecursor = (e) => {
      gsap.to(cursor, {
        duration: 0.6,
        ease: "sine",
        x: e.clientX,
        y: e.clientY,
      });
    };
    document.addEventListener("pointermove", movecursor);

    // Mouseover and mouseleave effects for various elements
    const handleMouseOver = (element, width, height, opacity = 1) => {
      gsap.to(cursor, {
        duration: 0.2,
        width,
        height,
        opacity,
        ease: "sine",
      });
    };

    const handleMouseLeave = (width, height, opacity = 0.1) => {
      gsap.to(cursor, {
        duration: 0.2,
        width,
        height,
        opacity,
        ease: "sine",
      });
    };

    const handleAccentCursorOver = (background) => {
      gsap.to(cursor, {
        duration: 0.2,
        background,
        ease: "sine",
      });
      cursor.classList.add("mil-accent");
    };

    const handleAccentCursorLeave = (background) => {
      gsap.to(cursor, {
        duration: 0.2,
        background,
        ease: "sine",
      });
      cursor.classList.remove("mil-accent");
    };

    const handleIconScale = (selector, scale) => {
      gsap.to(selector, { duration: 0.2, scale, ease: "sine" });
    };

    // Mouseover and mouseleave events for specific classes
    const elementsToWatch = [
      { selector: ".mil-drag", width: 90, height: 90 },
      { selector: ".mil-more", width: 90, height: 90 },
      { selector: ".mil-choose", width: 90, height: 90 },
    ];

    elementsToWatch.forEach((el) => {
      const elements = document.querySelectorAll(el.selector);
      elements.forEach((element) => {
        element.addEventListener("mouseover", () =>
          handleMouseOver(element, el.width, el.height)
        );
        element.addEventListener("mouseleave", () => handleMouseLeave(20, 20));
      });
    });

    // Accent cursor hover
    const accentElements = document.querySelectorAll(".mil-accent-cursor");
    accentElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleAccentCursorOver("#accentColor")
      );
      element.addEventListener("mouseleave", () =>
        handleAccentCursorLeave("#darkColor")
      );
    });

    // Icon scaling
    const dragElements = document.querySelectorAll(".mil-drag");
    dragElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-icon-1", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-icon-1", 0)
      );
    });

    const moreElements = document.querySelectorAll(".mil-more");
    moreElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-more-text", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-more-text", 0)
      );
    });

    const chooseElements = document.querySelectorAll(".mil-choose");
    chooseElements.forEach((element) => {
      element.addEventListener("mouseover", () =>
        handleIconScale(".mil-ball .mil-choose-text", 1)
      );
      element.addEventListener("mouseleave", () =>
        handleIconScale(".mil-ball .mil-choose-text", 0)
      );
    });

    // Reset cursor on inputs and links
    const inputAndLinkSelectors = `a:not(.mil-choose, .mil-more, .mil-drag, .mil-accent-cursor), 
                                   input, textarea, .mil-accordion-menu`;

    const resetElements = document.querySelectorAll(inputAndLinkSelectors);
    resetElements.forEach((element) => {
      element.addEventListener("mouseover", () => {
        gsap.to(cursor, { duration: 0.2, scale: 0, ease: "sine" });
        gsap.to(".mil-ball svg", { duration: 0.2, scale: 0 });
      });
      element.addEventListener("mouseleave", () => {
        gsap.to(cursor, { duration: 0.2, scale: 1, ease: "sine" });
        gsap.to(".mil-ball svg", { duration: 0.2, scale: 1 });
      });
    });

    // Cleanup function to remove event listeners on unmount
    return () => {
      document.removeEventListener("pointermove", movecursor);
      elementsToWatch.forEach((el) => {
        const elements = document.querySelectorAll(el.selector);
        elements.forEach((element) => {
          element.removeEventListener("mouseover", () =>
            handleMouseOver(element, el.width, el.height)
          );
          element.removeEventListener("mouseleave", () =>
            handleMouseLeave(20, 20)
          );
        });
      });
      accentElements.forEach((element) => {
        element.removeEventListener("mouseover", () =>
          handleAccentCursorOver("#accentColor")
        );
        element.removeEventListener("mouseleave", () =>
          handleAccentCursorLeave("#darkColor")
        );
      });
      resetElements.forEach((element) => {
        element.removeEventListener("mouseover", () => {
          gsap.to(cursor, { duration: 0.2, scale: 0, ease: "sine" });
          gsap.to(".mil-ball svg", { duration: 0.2, scale: 0 });
        });
        element.removeEventListener("mouseleave", () => {
          gsap.to(cursor, { duration: 0.2, scale: 1, ease: "sine" });
          gsap.to(".mil-ball svg", { duration: 0.2, scale: 1 });
        });
      });
    };
  }, []);


  return (
    <> 
    <div className="main-exchange">
      <section className="currency-conversion-rates">
        <div className="container">
          <div className="elementor-widget-container">
            <h2 className="mil-h2 mil-title-el mil-up mil-mb-30 currency-conversion-rates-text">
              {" "}
              <span>
                {" "}
                Currency <span className="mil-thin">Conversion</span>{" "}
              </span>
              Rates
            </h2>
          </div>
          <div  className="mil-up">
          <div className="currncy-homebar ">
            <Row gutter={[16, 16]}>
              <Col span={24} className=" ">
                <div className="table-fts-rate">
                  <Button
                    onClick={() => navigate("/")}
                    className="all-rate-button"
                  >
                    <div className="right-erroe">
                      <LeftOutlined />{" "}
                    </div>{" "}
                    Home
                  </Button>
                </div>
              </Col>

              <Col span={24} md={12} lg={4}>
                <Select
                  className="select-currncy"
                  placeholder="Select Currency"
                  onChange={handleCurrencyChange}
                  style={{ width: "100%" }}
                >
                  {data.map((cur) => (
                    <Option key={cur?.currency} value={cur?.currency}>
                      <Flag
                        code={cur?.currency?.substring(0, 2)}
                        style={{ width: "32px", marginRight: "8px" }}
                      />{" "}
                      {cur.currency}
                    </Option>
                  ))}
                </Select>
                {selectedCurrency && (
                  <span style={{ color: "orange" }}>
                    1 {selectedCurrency} &nbsp;&nbsp;&nbsp;&nbsp; =
                    &nbsp;&nbsp;&nbsp;&nbsp; {(1/conversionRate)?.toFixed(4)} SGD
                  </span>
                )}
              </Col>

              <Col span={24} md={12} lg={8}>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="currancy-cjad">
                      <Button
                        type="primary"
                        className="rate-btns"
                        onClick={() => setIsBuy(true)}
                        disabled={!selectedCurrency}
                      >
                        BUYING{" "}
                      </Button>
                      <span className="text-white">
                        Foreign Currencies to Singapore Dollar
                      </span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="currancy-cjad">
                      <Button
                        type="primary"
                        className="rate-btns"
                        onClick={() => setIsBuy(false)}
                        disabled={!selectedCurrency}
                      >
                        SELLING{" "}
                      </Button>
                      <span className="text-white">
                        Singapore Dollar to Foreign Currencies
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col span={24} md={12} lg={8}>
                <Row gutter={16} className="align-items-center">
                  <Col span={11}>
                    <Input
                      className="add-innput"
                      value={amount}
                      onChange={handleAmountChange}
                      addonAfter={isBuy ? selectedCurrency : "SGD"}
                      type="number"
                    />
                  </Col>
                  {selectedCurrency && (
                    <>
                      <Col span={1} className="equals-sign">
                        =
                      </Col>
                      <Col span={11}>
                        <span style={{color: "white"}}>
                          {(isBuy ? reverseAmount : convertedAmount)?.toFixed(
                            4
                          )}{" "}
                          {isBuy ? "SGD" : selectedCurrency}
                        </span>
                      </Col>
                    </>
                  )}
                </Row>
                <p className="text-white">How much would you like?</p>
              </Col>
            </Row>
          </div>

          <Row>
            <Col span={24}>
              <div className="buyer-table ">
                <div className="table-responsive">
                  <Table
                    className="currency-table"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </div>
              </div>
            </Col>
          </Row>
          </div>
        </div> 
        <div className="mil-frame-bottom exchange-frame">
          <div className="mil-current-page" />
          <div className="mil-back-to-top">
            {" "}
            <a href="#top" className="mil-link mil-dark mil-arrow-place">
              {" "}
              <span>Back to top</span>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="mil-arrow"
              >
                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
              </svg>
            </a>
          </div>
        </div> 
      </section>
      </div>
    </>
  );
}

export default ExchangeIndex;
