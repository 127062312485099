import { Row, Col, Button, Tabs, Table, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import flag from "../../assets/images/bahrain.png";
import RateIcon from "../../assets/images/rate_head_icon.png";
import Flag from "react-world-flags";
import useRequest from "../../hooks/useRequest";
import { RightOutlined } from "@ant-design/icons";

const { Option } = Select;

function CurrencyRateTable() {
  const { request } = useRequest();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isBuy, setIsBuy] = useState(false);
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [reverseAmount, setReverseAmount] = useState(0);
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   pageSize: 10,
  //   total: 0,
  // });

  // const columns = [
  //   {
  //     title: "Currency /SGD",
  //     key: "currency",
  //     dataIndex: "currency",
  //     width: 500,
  //     render: (_, { currency }) => {
  //       let code = currency?.substring(0,2);
  //       return (
  //         <>
  //           <Flag
  //             code={code}
  //             style={{ width: "32px", marginRight: "8px" }}
  //           />
  //           <span>
  //             {currency}
  //           </span>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Buy",
  //     key: "buy",
  //     dataIndex: "buy",
  //     className: "buy-column",
  //     width: 200,
  //     render: (_, { buy }) => {
  //       return <span>{buy}</span>;
  //     },
  //   },
  //   {
  //     title: "Sell",
  //     key: "sell",
  //     dataIndex: "sell",
  //     width: 200,
  //     className: "sell-column",
  //     render: (_, { sell }) => {
  //       return <span>{sell}</span>;
  //     },
  //   },
  // ];

  const handleCurrencyChange = (value) => {
    setSelectedCurrency(value);
    let flagAmount = data.find((item) => item?.currency === value);
    console.log(flagAmount);
    setConversionRate(flagAmount?.buy);
    setConvertedAmount(amount * flagAmount?.buy);
    setReverseAmount(amount / flagAmount?.buy);
  };

  const handleAmountChange = (e) => {
    let value = parseInt(e.target.value || 0);
    setAmount(value);
    setConvertedAmount(value * conversionRate);
    setReverseAmount(value / conversionRate);
  };

  const getForexData = () => {
    request({
      url: apiPath.common.forexPrice,
      method: "GET",
      onSuccess: (data) => {
        console.log(data);
        let final = data?.data?.filter((item) => {
          return ["USD", "AED", "EUR", "THB", "HKD", "JPY"].includes(
            item?.currency
          );
        });
        setData(final);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getForexData();
  }, []);

  // Currency data (You can replace this with dynamic data)
  // const currencyRates = [
  //   {
  //     key: "1",
  //     currency: "USD",
  //     buying: "32.35",
  //     selling: "32.45",
  //     flag: "🇺🇸",
  //   },
  //   {
  //     key: "2",
  //     currency: "GBP",
  //     buying: "43.20",
  //     selling: "43.40",
  //     flag: "🇬🇧",
  //   },
  //   {
  //     key: "3",
  //     currency: "EUR",
  //     buying: "35.95",
  //     selling: "36.15",
  //     flag: "🇪🇺",
  //   },
  //   {
  //     key: "4",
  //     currency: "JPY",
  //     buying: "0.2255",
  //     selling: "0.2270",
  //     flag: "🇯🇵",
  //   },
  //   {
  //     key: "5",
  //     currency: "CNY",
  //     buying: "4.630",
  //     selling: "4.680",
  //     flag: "🇨🇳",
  //   },
  // ];

  const columns = [
    {
      title: "CURRENCY / SGD",
      dataIndex: "currency",
      key: "currency",
      render: (text, { currency }) => {
        let code = currency?.substring(0, 2);
        return (
          <>
            <Flag code={code} style={{ width: "32px", marginRight: "8px" }} />
            <span>{currency}</span>
          </>
        );
      },
    },
    {
      title: "BUYING",
      dataIndex: "buying",
      key: "buying",
      render: (_, { buy }) => {
        return <span>{buy?.toFixed(4)}</span>;
      },
    },
    {
      title: "SELLING",
      dataIndex: "selling",
      key: "selling",
      render: (_, { sell }) => {
        return <span>{sell?.toFixed(4)}</span>;
      },
    },
  ];

  return (
    <>
      <section className="currency-conversion-rates">
        <div className="container">
          <div className="elementor-widget-container">
            <h2 className="mil-h2 mil-title-el mil-up mil-mb-30 currency-conversion-rates-text">
              {" "}
              <span>
                {" "}
                Currency <span className="mil-thin">Conversion</span>{" "}
              </span>
              Rates
            </h2>
          </div>
          <Row>
            <Col span={24}>
              {/* <div className="app-container">
                <div className="table-responsive">
                <Table
                  className="currency-table"
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                />
                </div>
              </div> */}
              {/* <div className="currency-exchange-container">
                <div class="rates-header">
                  <img src={RateIcon} class="vertical-middle" />
                  <h6 class="text-binding">
                    RATE UPDATE{" "}
                  </h6>
                </div>
                <div className="currency-table">
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                  />
                </div>
                <div className="table-fts">
                  <Button
                    className="all-rate-btn"
                    onClick={() => navigate("/exchange")}
                  >
                    <div className="right-erroe">
                      <RightOutlined />{" "}
                    </div>{" "}
                    View all currencies
                  </Button>
                </div>
                <div className="currency-converter">
                  <h3>GET CURRENCY</h3>
                  <Select
                    className="select-currncy"
                    placeholder="Select Currency"
                    onChange={handleCurrencyChange}
                  >
                    {data?.map((item) => {
                      return (
                        <Option value={item?.currency}>{item?.currency}</Option>
                      );
                    })}
                  </Select>

                  {selectedCurrency && (
                    <span style={{ color: "orange" }}>
                      1 {selectedCurrency} &nbsp;&nbsp;&nbsp;&nbsp; =
                      &nbsp;&nbsp;&nbsp;&nbsp; {(1/conversionRate)?.toFixed(4)} SGD
                    </span>
                  )}

                  <Row style={{ marginTop: 10 }} gutter={16}>
                    <Col span={12}>
                      <div className="currancy-cjad">
                        <Button
                          onClick={() => setIsBuy(true)}
                          type="primary"
                          className="rate-btns"
                        >
                          BUYING{" "}
                        </Button>
                        <span>Foreign Currencies to Singapore Dollar</span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="currancy-cjad">
                        <Button
                          onClick={() => setIsBuy(false)}
                          type="primary"
                          className="rate-btns"
                        >
                          SELLING{" "}
                        </Button>
                        <span>Singapore Dollar to Foreign Currencies</span>
                      </div>
                    </Col>
                  </Row>

                  <div className="conversion-section">
                    <p>How much would you like?</p>
                    <Row gutter={16} className="align-items-center">
                      <Col span={11}>
                        <Input
                          className="add-innput"
                          value={amount}
                          onChange={handleAmountChange}
                          addonAfter={isBuy ? selectedCurrency : "SGD"}
                          type="number"
                        />
                      </Col>
                      {selectedCurrency && (
                        <>
                          <Col span={1} className="equals-sign">
                            =
                          </Col>
                          <Col span={11}>
                            <span>
                              {(isBuy
                                ? reverseAmount
                                : convertedAmount
                              )?.toFixed(4)}{" "}
                              {isBuy ? "SGD" : selectedCurrency}
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default CurrencyRateTable;
