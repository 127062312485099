import React, { useEffect, useState } from "react";
import { Card, Form } from "antd";
import { useAppContext } from "../context/AppContext";
import useRequest from "../hooks/useRequest";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";

const AboutIndex = () => {
  const { setPageHeading } = useAppContext();
  const { request } = useRequest();
  const [content, setContent] = useState();

  const getAboutUs = () => {
    request({
      url: apiPath.content + `/about-us`,
      method: "GET",
      onSuccess: (data) => {
        if (data?.status) {
          // ShowToast(data?.message, Severty.SUCCESS);
          setContent(data?.data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (data) => {
        ShowToast(data?.message, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getAboutUs();
    setPageHeading("About Us");
  }, []);

  return (
    <>
      <section className="elementor-section elementor-top-section elementor-element elementor-element-d29950b elementor-section-full_width elementor-section-height-default elementor-section-height-default">
        <div className="elementor-container elementor-column-gap-no">
          <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90696a9">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div className="elementor-element elementor-element-737855b elementor-widget elementor-widget-ashley-services">
                <div className="elementor-widget-container">
                  {/* services */}
                  <section className="mil-dark-bg">
                    <div className="mi-invert-fix">
                      <div className="mil-animation-frame">
                        <div
                          className="mil-animation mil-position-1 mil-scale"
                          style={{
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform:
                              "translate3d(0px, 0px, 0px) scale(1.6698, 1.6698)",
                            top: "300",
                            right: "-100",
                          }}
                        >
                          <div className="mil-dodecahedron">
                            {Array.from({ length: 12 }).map((_, index) => (
                              <div key={index} className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          className="mil-animation mil-position-2 mil-scale"
                          style={{
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform:
                              "translate3d(0px, 0px, 0px) scale(1.0836, 1.0836)",
                            left: "150",
                          }}
                        >
                          <div className="mil-dodecahedron">
                            {Array.from({ length: 12 }).map((_, index) => (
                              <div key={index} className="mil-pentagon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d911749"
                            data-id="d911749"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-560f559 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="560f559"
                                data-element_type="section"
                                id="aboutus"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  <div
                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-8ea04dd"
                                    data-id="8ea04dd"
                                    data-element_type="column"
                                  >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        className="elementor-element elementor-element-16e7d98 elementor-widget elementor-widget-ashley-about-us"
                                        data-id="16e7d98"
                                        data-element_type="widget"
                                        data-widget_type="ashley-about-us.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <h2 className="mil-h2 mil-title-el mil-up mil-mb-30 about-text-section">
                                            {" "}
                                            <span>
                                              About
                                              <span className="mil-thin">
                                                Us
                                              </span>{" "}
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-abcbf58 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="abcbf58"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  <div
                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-af6c328"
                                    data-id="af6c328"
                                    data-element_type="column"
                                  >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        className="elementor-element elementor-element-98f317f elementor-widget elementor-widget-ashley-description"
                                        data-id="98f317f"
                                        data-element_type="widget"
                                        data-widget_type="ashley-description.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div
                                            className="mil-text mil-up mil-mb-30 "
                                            style={{
                                              translate: "none",
                                              rotate: "none",
                                              scale: "none",
                                              transform: "translate(0px, 0px)",
                                              opacity: 1,
                                            }}
                                          >
                                            <div className="about-text-p">
                                              <div dangerouslySetInnerHTML={{__html: content?.description}} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-4ce0755 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4ce0755"
                                data-element_type="section"
                              >
                                <div className="elementor-container elementor-column-gap-default">
                                  <div
                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5687b2a"
                                    data-id="5687b2a"
                                    data-element_type="column"
                                  >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                      <div
                                        className="elementor-element elementor-element-96bafb1 elementor-widget elementor-widget-ashley-about-quote"
                                        data-id="96bafb1"
                                        data-element_type="widget"
                                        data-widget_type="ashley-about-quote.default"
                                      >
                                        <div className="elementor-widget-container">
                                          <div className="mil-about-quote">
                                            <div className="mil-avatar mil-up">
                                              {" "}
                                              <img
                                                decoding="async"
                                                src="wp-content/uploads/2023/10/faces-c-2.jpg"
                                                alt="Founder"
                                              />
                                            </div>
                                            <h6 className="mil-quote mil-up">
                                              {" "}
                                              <span>
                                                {" "}
                                                Passionately Creating{" "}
                                                <span className="mil-thin">
                                                  Design Wonders:
                                                </span>{" "}
                                                Unleashing{" "}
                                                <span className="mil-thin">
                                                  Boundless Creativity
                                                </span>{" "}
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                          <div
                            className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ef071d5"
                            data-id="ef071d5"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-d612217 elementor-widget elementor-widget-ashley-about-image"
                                data-id="d612217"
                                data-element_type="widget"
                                data-widget_type="ashley-about-image.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="mil-about-photo">
                                    <div className="mil-lines-place">
                                      <svg
                                        width={250}
                                        viewBox="0 0 300 1404"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mil-lines"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M1 892L1 941H299V892C299 809.71 232.29 743 150 743C67.7096 743 1 809.71 1 892ZM0 942H300V892C300 809.157 232.843 742 150 742C67.1573 742 0 809.157 0 892L0 942Z"
                                          className="mil-move"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M299 146V97L1 97V146C1 228.29 67.7096 295 150 295C232.29 295 299 228.29 299 146ZM300 96L0 96V146C0 228.843 67.1573 296 150 296C232.843 296 300 228.843 300 146V96Z"
                                          className="mil-move"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M299 1H1V1403H299V1ZM0 0V1404H300V0H0Z"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M150 -4.37115e-08L150 1404L149 1404L149 0L150 -4.37115e-08Z"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M150 1324C232.29 1324 299 1257.29 299 1175C299 1092.71 232.29 1026 150 1026C67.7096 1026 1 1092.71 1 1175C1 1257.29 67.7096 1324 150 1324ZM150 1325C232.843 1325 300 1257.84 300 1175C300 1092.16 232.843 1025 150 1025C67.1573 1025 0 1092.16 0 1175C0 1257.84 67.1573 1325 150 1325Z"
                                          className="mil-move"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M300 1175H0V1174H300V1175Z"
                                          className="mil-move"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M150 678C232.29 678 299 611.29 299 529C299 446.71 232.29 380 150 380C67.7096 380 1 446.71 1 529C1 611.29 67.7096 678 150 678ZM150 679C232.843 679 300 611.843 300 529C300 446.157 232.843 379 150 379C67.1573 379 0 446.157 0 529C0 611.843 67.1573 679 150 679Z"
                                          className="mil-move"
                                        />
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M299 380H1V678H299V380ZM0 379V679H300V379H0Z"
                                          className="mil-move"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      className="mil-up mil-img-frame"
                                      style={{
                                        paddingBottom: "160%",
                                        translate: "none",
                                        rotate: "none",
                                        scale: "none",
                                        transform: "translate(0px, 0px)",
                                        opacity: 1,
                                      }}
                                    >
                                      <noscript>
                                        &lt;img decoding="async"
                                        src="wp-content/uploads/2023/10/photo-1.jpg"
                                        alt="" class="mil-scale"
                                        data-value-1="1" data-value-2="1.2"
                                        /&gt;
                                      </noscript>
                                      <img
                                        decoding="async"
                                        src="https://ashley.bslthemes.com/wp-content/uploads/2023/10/photo-1.jpg"
                                        data-src="https://ashley.bslthemes.com/wp-content/uploads/2023/10/photo-1.jpg"
                                        alt=""
                                        className="mil-scale ls-is-cached lazyloaded"
                                        data-value-1={1}
                                        data-value-2="1.2"
                                        style={{
                                          translate: "none",
                                          rotate: "none",
                                          scale: "none",
                                          transform:
                                            "translate3d(0px, 0px, 0px) scale(1.1462, 1.1462)",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* services end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutIndex;
